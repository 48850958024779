import {library, dom} from '@fortawesome/fontawesome-svg-core'

import {faCcVisa, faCcMastercard} from '@fortawesome/free-brands-svg-icons'
import {
  faPlus,
  faMinus,
  faPencil,
  faFileInvoice,
  faKey,
  faSignOut,
  faGear,
  faTimes,
  faTrash,
  faCheck,
  faSpinner,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCalendar,
  faUser,
  faCreditCard,
  faEnvelope,
  faFilePdf,
  faSearch,
  faSortAsc,
  faSortDesc,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faPlus, faMinus, faPencil, faFileInvoice, faKey, faSignOut, faGear, faTimes, faTrash, faCheck, faSpinner, faChevronLeft, faChevronRight, faChevronDown, faCalendar, faUser, faEnvelope, faCcVisa, faCcMastercard, faCreditCard, faFilePdf, faSearch, faSortAsc, faSortDesc, faPaperclip)

export default FontAwesomeIcon

dom.watch()
